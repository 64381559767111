import { AuthGuard } from './services/auth.guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { PartnersPageComponent } from './components/pages/partners-page/partners-page.component';
import { NewsPageComponent } from './components/pages/news-page/news-page.component';
import { ProjectPageComponent } from './components/pages/project-page/project-page.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { OpenlabPageComponent } from './components/pages/partners_pages/openlab-page/openlab-page.component';
import { LiucPageComponent } from './components/pages/partners_pages/liuc-page/liuc-page.component';
import { IrccsPageComponent } from './components/pages/partners_pages/irccs-page/irccs-page.component';
import { EttPageComponent } from './components/pages/partners_pages/ett-page/ett-page.component';
import { DongnocchiPageComponent } from './components/pages/partners_pages/dongnocchi-page/dongnocchi-page.component';
import { BicoccaPageComponent } from './components/pages/partners_pages/bicocca-page/bicocca-page.component';
import { AstirPageComponent } from './components/pages/partners_pages/astir-page/astir-page.component';
import { ChallengesPageComponent } from './components/pages/challenges-page/challenges-page.component';
import { ChallengeDetailPageComponent } from './components/pages/challenge-detail-page/challenge-detail-page.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { PrivacyPageComponent } from './components/pages/privacy-page/privacy-page.component';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { InfoPageComponent } from './components/pages/info-page/info-page.component';
import { WavesPageComponent } from './components/waves-page/waves-page.component';


const routes: Routes = [

  { path: 'home', component: HomePageComponent },
  { path: 'project', component: ProjectPageComponent },
  { path: 'partners', component: PartnersPageComponent },
  { path: 'news', component: NewsPageComponent },
  { path: 'news/:id', component: NewsDetailComponent },
  { path: 'challenges', component: ChallengesPageComponent },
  { path: 'challenge/:id', component: ChallengeDetailPageComponent },
  { path: 'waves', component: WavesPageComponent },
  { path: 'privacy', component: PrivacyPageComponent },

  { path: 'dongnocchi', component: DongnocchiPageComponent },
  { path: 'openlab', component: OpenlabPageComponent },
  { path: 'astir', component: AstirPageComponent },
  { path: 'irccs', component: IrccsPageComponent },
  { path: 'bicocca', component: BicoccaPageComponent },
  { path: 'ett', component: EttPageComponent },
  { path: 'liuc', component: LiucPageComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'info', component: InfoPageComponent },
  { path: '404', component: PageNotFoundComponent},

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
