
  <div *ngIf="news" class="page-container">
    <div class="image-container">
      <span class="date-label">{{getFormattedDate(news.date)}}</span>
      <img class="img-news" src="{{news.imageUrl}}" onerror="this.src='./assets/images/placeholder.png'"/>
    </div>
    <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
      <ngb-panel id="panel-0" cardClass="accordion-card">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-center">
            <h3 class="m-0">{{news.title}}</h3>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <p class="news-description" [innerHTML]="news.description">
          </p>
          <div *ngIf="news.url.length" class="tools">
            <a class="btn btn-open subscribe-btn" href="{{news.url}}" target="_blank">
              Vai alla Notizia
            </a>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
