<div *ngIf="challenge">
  <app-challenge-card
  class="challenge-card"
  [challenge] = "challenge"
  ></app-challenge-card>
  <div class="page-container">
    <div class="short-description-label" [innerHTML]="challenge.shortDescr">
    </div>
    
    <div class="video-container">
      <iframe class="video" width="560" height="315" src="https://www.youtube.com/embed/4WRG6oaTOOs" title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
      <ngb-panel id="panel-0" cardClass="accordion-card">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
           <h3 class="m-0">Descrizione</h3>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <p class="challenge-long-desciption" [innerHTML]="challenge.longDescr">
          </p>
          <div class="tools">
            <button *ngIf="!userSubscribed && moreInfo === false" class="btn btn-open subscribe-btn" (click)="showMore()">
              <!-- {{isLogged ? "Iscriviti alla Challenge" : "Vuoi saperne di più?"}} -->
              {{challenge.techDescr == "" ? "Iscriviti alla Challenge" : "Voi saperne di più?"}}
            </button>
            <p class="challenge-long-desciption" *ngIf="moreInfo" [innerHTML]="challenge.techDescr"></p>
            <div *ngIf="moreInfo" class="challenge-button">
              <!-- <button class="btn btn-open subscribe-btn" (click)="subscribe()">Cosa facciamo</button> -->
              <a href="https://dashboard.smart-touch-id.com/" target="_blank" class="btn btn-open subscribe-btn">Iscriviti alla Challenge</a>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <!-- <ngb-panel id="panel-1" cardClass="accordion-card">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
           <h3 class="m-0">Descrizione Tecnica</h3>
           <button *ngIf="!isLogged" (click)="login()" class="btn btn-open">Registrati</button>
           <a target="_blank" href="https://dashboard.smart-touch-id.com/"><button *ngIf="!isLogged" class="btn btn-open">Registrati</button></a>
           <button *ngIf="isLogged" ngbPanelToggle class="btn btn-open">{{ opened ? 'Chiudi' : 'Apri' }}</button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <p [innerHTML]="challenge.techDescr">
          </p>
        </ng-template>
      </ngb-panel> -->
    </ngb-accordion>
  </div>
</div>




