<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">Progetto SMART&Touch-ID</h3>
        </div>
        <!-- <div class="video-container">
          <iframe class="video" width="560" height="315" src="https://www.youtube.com/embed/4WRG6oaTOOs" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        </div> -->
      </ng-template>
      <!-- <video width="100%">
        <source src="https://youtu.be/4WRG6oaTOOs" type="video">
      </video> -->

      <ng-template ngbPanelContent>
        <div class="declaration">
          <div class="declaration-row">
            <span><strong class="key-word">SMART</strong></span><span>Sono le soluzioni tecnologiche creative e
              innovative che stiamo
              cercando,</span>
          </div>
          <div class="declaration-row">
            <span><strong class="key-word">TOUCH</strong></span><span>capaci di toccare il cuore dei bisogni di cura e
              assistenza/benessere</span>
          </div>
          <div class="declaration-row">
            <span><strong class="key-word">ID</strong></span><span>in modo personalizzato</span>
          </div>
        </div>
        <div class="project-description">
          <p>
            Sono questi i principi che hanno guidato la nascita di questo HUB, una rete di enti, aziende e competenze,
            ma soprattutto di persone, che collabora per la creazione di soluzioni innovative per la cura riabilitativa
            delle persone affette da patologie croniche non trasmissibili e dei loro bisogni espressi ed inespressi.
          </p>
          <p>
            Le malattie croniche non trasmissibili sono in cima all’elenco dei maggiori problemi di sanità pubblica
            indicati dalle organizzazioni mondiali. Malattie cardiovascolari, tumori, patologie respiratorie croniche,
            diabete, malattie neurodegenerative… nella sola Lombardia sono circa 3 milioni di persone. Nel mondo, queste
            patologie, rappresentano circa il 70% della mortalità, e assorbono di gran lunga la maggior parte delle
            risorse sanitarie nazionali.
          </p>
          <p>
            L'HUB SMART&Touch-ID si pone al servizio dei cittadini, degli istituti di cura, delle associazioni di assistenza presenti sul
            territorio e degli innovatori tecnologici. Con l’aiuto e l’esperienza di ciascuna competenza, SMART&Touch-ID ha
            l’obiettivo di costituire un modello di progettualità e sperimentazione per la nascita di soluzioni capaci
            di mostrarsi vincenti nel rispondere alle esigenze di salute e benessere peculiari dei vari ambiti della
            cronicità.
          </p>
          <p>
            Grazie ad un contributo FESR (POR FESR 2014-2020 Call HUB Ricerca e Innovazione Azione I.1.b.1.3) erogato da Regione Lombardia, l'HUB SMART&Touch-ID ha
            l’opportunità di concentrarsi sulle strategie, i servizi e le tecnologie da applicare ai percorsi di
            riabilitazione di questa categoria di pazienti, identificando soluzioni che siano anche sostenibili
            economicamente per i cittadini, gli enti e le aziende. Per riuscirci, l’HUB ritiene prioritario agganciare
            in modo diretto i professionisti del settore e guidare l’innovazione tecnologica all’ascolto e all’analisi
            delle esigenze di salute e benessere, che sono in continua evoluzione. È quindi fondamentale coinvolgere
            pazienti, operatori del settore e servizi di assistenza per individuare le priorità e le nuove SFIDE (<a
              href="https://smart-touch-id.com/#/challenges">scopri le nostre
              challenge</a>), per co-progettare prototipi tecnologici e modelli di cura e per
            testarne, infine, la validità nel domicilio, in clinica e nel territorio.
          </p>
          <p>
            Su questo terreno, le competenze degli esperti in ambito sanitario, tecnologico e accademico guideranno i
            percorsi di innovazione per ogni sfida, in modo che le migliori intuizioni/idee proposte possano
            concretizzarsi in promettenti soluzioni digitali. Queste verranno infine sottoposte a valutazioni di
            sostenibilità economica e di rimborsabilità, strumenti indispensabili per le aziende di tecnologia che
            ambiranno ad affrontare la sfida del mercato.
          </p>
          <p>
            Un grande risultato del progetto SMART&Touch-ID sarà, inoltre, il consolidamento di una rete di
            collaborazioni con partner industriali, Pubblica Amministrazione e Organismi di Ricerca, per diffondere le
            conoscenze
            sviluppate e maturate anche in ottica nazionale e internazionale.
          </p>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">Informazioni Generali</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>
          <strong> BANDO</strong> : HUB RICERCA e INNOVAZIONE
        </p>
        <p>
          <strong> AREA DI SPECIALIZZAZIONE S3 : </strong> SMART CITIES and COMMUNITIES
        </p>
        <p>
          <strong> MACROTEMATICA S3 :</strong> SMART HEALTH CARE
        </p>
        <p class="project-description">
          <strong> TEMA DI SVILUPPO S3 : </strong> IS4.1 Sviluppo di dispositivi, sistemi, anche ICT, e/o metodi
          innovativi per la riabilitazione neurocognitiva e/o neuromotoria
        </p>
        <P>
          <strong> DATA DI INIZIO : </strong> 07 gennaio 2020
        </P>
        <p>
          <strong> DATA DI FINE :</strong> 30 Novembre 2022 (Durata: 35 Mesi (30 mesi più 5 mesi di proroga))
        </p>
        <p>
          <strong> BUDGET : </strong> € 6.647.699,00
        </p>
        <p>
          <!--<strong> CO-FINANZIAMENTO DA REGIONE LOMBARDIA :</strong> € 2.914.253,54-->
          <strong> CONTRIBUTO FESR Totale progetto :</strong> € 2.914.253,54
          (<strong>Fondazione Don Carlo Gnocchi - Onlus :</strong> € 560.011,78 ; <strong>Università degli Studi di Milano - Bicocca :</strong> € 560.020,00; <strong>Università Carlo Cattaneo - Liuc :</strong> € 270.152,08; <strong>Centro San Giovanni di Dio - Fatebenefratelli :</strong> € 338.000,00; <strong>ETT S.p.A. :</strong> € 498.122,50; <strong>Astir S.r.L. :</strong> € 553.247,18; <strong>Open Lab S.r. L. :</strong> € 134.700,00).
        </p>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">Workplan</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p> Il progetto si articola in 4 fasi:</p>
        <p> FASE 1: Strutturazione dell’HUB e governance </p>
        <p> FASE 2: Validazione circolare del flow HUB </p>
        <p> FASE 3: Consolidamento dell’HUB </p>
        <p> FASE 4: Apertura su scala nazionale e internazionale </p>

        <img width="100%" src="./assets/images/timeline.png">

        <p> Il piano di lavoro &egrave; diviso in 7 pacchetti di lavoro (work packages – WP) </p>
        <p> WP1: Project Management </p>
        <p> WP2: Modello, processo e indicatori dell’HUB</p>
        <p> WP3: Sviluppo e validazione della componente SMART</p>
        <p> WP4: Sviluppo e validazione della componente Touch</p>
        <p> WP5: Sviluppo e validazione della componente ID </p>
        <p> WP6: Governance delle soluzioni tecnologiche e sostenibilità</p>
        <p> WP7: Definizione di un piano di sfruttamento dei risultati</p>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
