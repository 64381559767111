import { NewsService } from './../../../services/news.service';
import { Component, OnInit } from '@angular/core';
import { NewsJson, NewsApiResponse } from 'src/app/model/news';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {

  public news: NewsJson[];
  public newsApi: NewsApiResponse;

  constructor(private newsServ: NewsService) { }

  ngOnInit(): void {

    this.newsServ.getAllNews().subscribe(news => {
      this.news = news;
      this.news.sort((a , b) => b.date.seconds - a.date.seconds);

    //   this.newsServ.getAllNewsApi().subscribe(news => {
    //     news.forEach(n => {
    //       this.news.push(n);
    //       this.news.sort((a , b) => b.date.seconds - a.date.seconds);
    //     })
    //   })
    });
  }

  public getShortText(text: string, words: number): string{
    return text.split(' ').slice(0, words).join(' ') + '...';
  }


  public getFormattedDate(date: any): string{
    return formatDate(new Date(date.seconds * 1000), 'fullDate', 'it');
  }
}
