<div class="modal-container">

  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink><strong>IL NOSTRO OBIETTIVO</strong></a>
      <ng-template ngbNavContent>
        <div class="tab-content-container">
          <div class="tab-content-image">
            <img class="tab-image" src="./assets/images/stid_logo.png">
          </div>
          <div class="tab-content-description">
            <h5 class="tab-title">IL NOSTRO OBIETTIVO</h5>
            <p class="tab-text">
              Promuovere la salute e il benessere dei pazienti con disabilità cronica attraverso
              tecnologie abilitanti il percorso di cura.
            </p>
            <h5 class="tab-title">L'IDEA</h5>
            <p class="tab-text">
              Supportare la creazione di percorsi di cura con soluzioni tecnologiche:
            </p>
            <p class="tab-text">
              - <strong>SMART</strong> di creatività e innovazione.<br>
              - <strong>TOUCH</strong> in linea con i bisogni di salute e di benessere delle persone con disabilità croniche.<br>
              - <strong>ID</strong> in modo personalizzato e scientificamente validato.<br>
            </p>
          </div>
        </div>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <button class="btn btn-sm btn-outline-dark" (click)="nav.select(2)">Vai a Il Cuore di HUB</button>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink><strong>IL CUORE DI HUB</strong></a>
      <ng-template ngbNavContent>
        <div class="tab-content-container">
          <div>
            <img class="icon animate" src="./assets/images/heart.svg" alt="">
          </div>
          <div class="tab-content-description">
            <h5 class="tab-title">IL CUORE DI HUB</h5>
            <p class="tab-text">
              SONO le esperienze di vita con la cronicità... e i BISOGNI delle persone che vivono con la cronicità.
            </p>
            <p class="tab-text">
              PERCHÈ le persone con cronicità sono PROTAGONISTE del percorso di cura.
            </p>
            <p class="tab-text">
              Qualunque soluzione tecnologica per la riabilitaziione è costruita INSIEME CON
              le persone con cronicità ed è PROPRIO PER LORO.
            </p>
          </div>
        </div>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <button class="btn btn-sm btn-outline-dark" (click)="nav.select(3)">Vai a Il Terreno di HUB</button>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink><strong>IL TERRENO DI HUB</strong></a>
      <ng-template ngbNavContent>
        <div class="tab-content-container">
          <div>
            <img class="icon animate" src="./assets/images/plant.svg" alt="">
          </div>
          <div class="tab-content-description">
            <h5 class="tab-title">IL TERRENO DI HUB</h5>
            <p class="tab-text">
              È la TUA professionalità nel proporre percorsi di benessere e di cura per la cronicità.
            </p>
            <p class="tab-text">
              PERCHÈ in HUB trovano voce le ESIGENZE nel processo di management delle soluzioni tecnologiche
              per la cronicità.
            </p>
            <p class="tab-text">
              PERCHÈ HUB lavora per rendere tali soluzioni per la riabilitazione, oltre che efficaci, anche
              SOSTENIBILI, per tutti i tipi di ORGANIZZAZIONE SANITARIA in cui professionisti che partecipano
              ai percorsi di cura oer la cronicità sono PARTE INTEGRANTE dell'ecosistema delle soluzioni per la
              riabilitazione.
            </p>
          </div>
        </div>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <button class="btn btn-sm btn-outline-dark" (click)="nav.select(4)">Vai a Il Motore di HUB</button>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink><strong>IL MOTORE DI HUB</strong></a>
      <ng-template ngbNavContent>
        <div class="tab-content-container">
          <div>
            <img class="icon animate" src="./assets/images/car.svg" alt="">
          </div>
          <div class="tab-content-description">
            <h5 class="tab-title">IL MOTORE DI HUB</h5>
            <p class="tab-text">
              Le TUE soluzioni tecnologiche sono il <strong>MOTORE di HUB</strong>.
            </p>
            <p class="tab-text">
              PERCHÈ in HUB TROVANO la strada per diventare PARTE INTEGRANTE dei percorsi di cura
              (i pazienti, le aziende, e chi usa le soluzioni, operano INSIEME).
            </p>
            <p class="tab-text">
              Perché potrai progettare nuove soluzioni ANTICIPANDO le traiettorie del mercato.
            </p>
            <p class="tab-text">
              Le persone, gli Enti, gli esperti che lavoreranno con te sono ATTORI della MEDESIMA
              RETE e ne saranno i primi utilizzatori e i primi sponsor.
            </p>
          </div>
        </div>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <button class="btn btn-sm btn-outline-dark" (click)="nav.select(5)">Vai a Cosa Facciamo</button>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <a ngbNavLink><strong>COSA FACCIAMO</strong></a>
      <ng-template ngbNavContent>
        <div class="tab-content-container">
          <div>
            <img class="icon animate" src="./assets/images/stid_logo.png" alt="">
          </div>
          <div class="tab-content-description">
            <h5 class="tab-title">COSA FACCIAMO</h5>
            <p>
              <strong>Lanciamo SFIDE</strong> (le CHALLENGE di HUB), in cui le aziende possono proporre le loro soluzioni TECNOLOGICHE per la riabilitazione.
            </p>
            <p>
              <strong>Lanciamo WAVES</strong>
            </p>
            <p>
              Facciamo emergere le ESPERIENZE e i BISOGNI delle persone che vivono con una cronicità per PROGETTARE insieme una RIABILITAZIONE di ECCELLENZA. 
              Le esperienze sono le onde – le waves. Non è una semplice raccolta di opinioni. E’ dare corpo e sostanza al mare delle esperienze dei cittadini 
              per trasformare la riabilitazione in funzione di ciò di cui hanno davvero bisogno.
            </p>
            <p>
              Offriamo <strong>MENTORSHIP</strong> sulla soluzione tecnologica, ossia ANALISI DI METODO E OPPORTUNITÀ DI EVOLUZIONE PROGETTUALE del prototipo, rispetto a:
            </p>
            <ul class="tab-text">
              <li style="color:#EF2D23">DESIGN E SVILUPPO</li>
              <li style="color:#9A8CC7">EFFICACIA DI BENESSERE</li>
              <li style="color:orangered">EFFICACIA CLINICA</li>
              <li style="color:#5D953A">SOSTENIBILITÁ ECONOMICA, ORGANIZZATIVA E RISPONDENZA AI CRITERI REGOLATORI</li>
            </ul>
          </div>
        </div>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <button class="btn btn-sm btn-outline-dark" (click)="nav.select(6)">Avanti</button>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <a ngbNavLink><strong>HUB HA BISOGNO DI TE</strong></a>
      <ng-template ngbNavContent>
        <H1 class="tab-subscription-title">RACCOGLI LA SFIDA E ISCRIVITI!</H1>
        <div class="tab-button-container">
          <button class="btn btn-sm btn-outline-danger" (click)="close()">Annulla</button>
          <a href="https://dashboard.smart-touch-id.com/" target="_blank" class="btn btn-sm btn-outline-dark">Iscriviti ad
            HUB</a>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>
