import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './../../services/auth.service';
import { auth, User } from 'firebase/app';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { SigninModalComponent } from '../signin-modal/signin-modal.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isButtonClicked: boolean;

  public user: User;


  constructor(public authService: AuthService, public modalService: NgbModal, public userService: UserService) {
    this.isButtonClicked = false;

    // this.authService.user.subscribe(user => {
    //   if (user) {
    //     this.user = user;
    //   } else {
    //     this.user = null;
    //   }
    // });
  }

  ngOnInit(): void {
  }


  public buttonClicked(): void{
    if (this.isButtonClicked) {
      this.isButtonClicked = false;
    }
    else{
      this.isButtonClicked = true;
    }
  }

  // public login(): void{
  //   this.authService.loginWithGoogle().subscribe(userData => {
  //     this.userService.getUserData(userData.user.uid).subscribe(stidUser => {
  //       if (!stidUser){
  //         const modalRef = this.modalService.open(SigninModalComponent, {size: 'lg'});
  //         modalRef.componentInstance.user = userData.user;
  //       }
  //     });
  //   },
  //   err => {
  //     console.log(err);
  //   });
  // }

  // public logout(): void{
  //   this.authService.logout();
  // }

}
