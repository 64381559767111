<div class="page-container">
  <div class="intro-container">
    <h5 class="intro">
      Ti stanno a cuore la <strong>salute</strong> e il <strong>benessere</strong> delle persone con una <strong>patologia cronica</strong>? <br>
Fai parte di un ente che cura persone con patologie croniche o che si occupa del loro benessere? <br>
Lavori in un'azienda capace di ideare <strong>soluzioni tecnologiche innovative</strong> per la riabilitazione? <br>

Siamo un <strong>HUB</strong> che coinvolge un insieme di competenze utili per <strong>creare e validare percorsi di cura riabilitativi</strong>.
Vuoi mettere a frutto il tuo impegno, la tua esperienza, la tua creatività, le tue competenze? <br>

    </h5>
    <div *ngIf="!isLogged" ng class="tools">
      <br>
      <div class="button-container">
        <button class="btn btn-open subscribe-btn" (click)="login()">
          VUOI SAPERNE DI PIÙ?
        </button>
        <a href="https://dashboard.smart-touch-id.com/" target="_blank">
          <button class="btn btn-open subscribe-btn">
            ENTRA IN HUB
          </button>
        </a>
      </div>
    </div>

    <div class="video-container">
      <iframe class="video" width="560" height="315" src="https://www.youtube.com/embed/4WRG6oaTOOs" title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    </div>
  </div>

  <div class="yellow-title">
    <h3 class="news-title">Challenge attive</h3>
  </div>
  <div *ngIf="challenges">
    <ngb-carousel [showNavigationIndicators]="challenges.length>1?true:false" [showNavigationArrows]="challenges.length>1?true:false">
      <ng-template *ngFor="let challenge of challenges" ngbSlide>
        <app-challenge-card routerLink="/challenge/{{challenge.id}}" class="challenge-card" [challenge]="challenge">
        </app-challenge-card>
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="news-container">
    <div class="py-5">
      <h3 class="news-title">News</h3>
      <br>
      <div class="yellow-line"></div>
      <br>
      <div class="container">
        <div class="row hidden-md-up">
          <div *ngFor="let news of news" class="col-md-6 col-lg-4 col-sm-12">
            <a routerLink="/news/{{news.id}}">
              <div class="card card-news">
                <img class="card-img-top img-news" src="{{news.imageUrl}}" />
                <div class="yellow-line"></div>
                <div class="card-body card-news-body">
                  <span class="card-text">
                    {{news.title}}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
