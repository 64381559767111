<div class="modal-container">

  <h3>Informazioni Aggiuntive</h3>

    <div style="width:100%" class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
      <button class="btn btn-role" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">Perchè ti iscrivi alla challenge?</button>
      <div style="width:100%" ngbDropdownMenu aria-labelledby="dropdownManual">
        <div style="width:100%" *ngFor="let role of roles">
          <button ngbDropdownItem class="btn btn-role" *ngFor="let subcategory of role.list" (click)="selectRole(subcategory)">{{subcategory.name}}</button>
        </div>
      </div>
    </div>

    <p style="width: 100%;">
      {{selectedRole? selectedRole.name : ""}}
    </p>

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">


    <div class="form-group">
      <label>Telefono: *</label>
      <input [(ngModel)] = "this.user.phone" type="text" class="form-control" formControlName="phone">
      <div *ngIf="profileForm.controls['phone'].invalid && (profileForm.controls['phone'].dirty || profileForm.controls['phone'].touched)" class="alert alert-danger">
        <div *ngIf="profileForm.controls['phone'].errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Bio: *</label>
      <textarea [(ngModel)] = "this.user.biosketch" name="Text1" cols="40" rows="5" class="form-control" formControlName="bioSketch"></textarea>
      <!-- <input type="text" class="form-control" formControlName="bioSketch"> -->
      <div *ngIf="profileForm.controls['bioSketch'].invalid && (profileForm.controls['bioSketch'].dirty || profileForm.controls['bioSketch'].touched)" class="alert alert-danger">
        <div *ngIf="profileForm.controls['bioSketch'].errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <span>* campo obbligatorio</span>

    <div class="form-group">


    </div>

    <p class="btn-container">
      <button class="btn btn-outline-danger" (click)="close()">Annulla</button>
      <button class="btn btn-outline-warning" (click)="onSubmit()">Accedi</button>
    </p>


  </form>
</div>
