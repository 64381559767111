import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { PartnersPageComponent } from './components/pages/partners-page/partners-page.component';
import { NewsPageComponent } from './components/pages/news-page/news-page.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { OpenlabPageComponent } from './components/pages/partners_pages/openlab-page/openlab-page.component';
import { LiucPageComponent } from './components/pages/partners_pages/liuc-page/liuc-page.component';
import { IrccsPageComponent } from './components/pages/partners_pages/irccs-page/irccs-page.component';
import { EttPageComponent } from './components/pages/partners_pages/ett-page/ett-page.component';
import { DongnocchiPageComponent } from './components/pages/partners_pages/dongnocchi-page/dongnocchi-page.component';
import { BicoccaPageComponent } from './components/pages/partners_pages/bicocca-page/bicocca-page.component';
import { AstirPageComponent } from './components/pages/partners_pages/astir-page/astir-page.component';
import { ChallengesPageComponent } from './components/pages/challenges-page/challenges-page.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ChallengeCardComponent } from './components/challenge-card/challenge-card.component';
import { ChallengeDetailPageComponent } from './components/pages/challenge-detail-page/challenge-detail-page.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SigninModalComponent } from './components/signin-modal/signin-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SubscribeModalComponent } from './components/subscribe-modal/subscribe-modal.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { PrivacyPageComponent } from './components/pages/privacy-page/privacy-page.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { ProjectPageComponent } from './components/pages/project-page/project-page.component';
import { InfoPageComponent } from './components/pages/info-page/info-page.component';
import { WavesPageComponent } from './components/waves-page/waves-page.component';

const cookieConfig: NgcCookieConsentConfig = {
    // cookie: {
    //   domain: 'localhost'
    // },
    // position: 'bottom',
    // theme: 'edgeless',
    // palette: {
    //   popup: {
    //     background: '#313131',
    //     text: '#ffffff',
    //     link: '#ebb731'
    //   },
    //   button: {
    //     background: '#ebb731',
    //     text: '#313131',
    //     border: 'transparent'
    //   }
    // },
    // type: 'info',
    // content: {
    //   message: 'Questo sito utilizza cookie per offrirti un\'esperienza di navigazione sempre migliore.',
    //   dismiss: 'Accetto',
    //   deny: 'Rifiuto',
    //   link: 'Vuoi saperne di più?',
    //   href: 'https://cookiesandyou.com',
    //   policy: 'Cookie Policy'
    // }
    cookie: {
      domain: 'www.smart-touch-id.com'
    },
    position: 'bottom',
    theme: 'edgeless',
    palette: {
      popup: {
        background: '#313131',
        text: '#fff',
        link: '#ebb731'
      },
      button: {
        background: '#ebb731',
        text: '#313131',
        border: 'transparent',
      }
    },
    type: 'info',
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}}
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
        <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
      </span>
      `,
    },
    content:{
      message: 'Questo sito utilizza cookie per offrirti un\'esperienza di navigazione sempre migliore.',
      dismiss: 'Accetto',
      cookiePolicyLink: 'Vuoi saperne di più?',
      cookiePolicyHref: 'https://cookiesandyou.com',

      privacyPolicyLink: 'Privacy Policy',
      privacyPolicyHref: '/#/privacy'
    }
};



declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}



// the second parameter 'fr' is optional
registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PartnersPageComponent,
    NewsPageComponent,
    ProjectPageComponent,
    PageNotFoundComponent,
    OpenlabPageComponent,
    LiucPageComponent,
    IrccsPageComponent,
    EttPageComponent,
    DongnocchiPageComponent,
    BicoccaPageComponent,
    AstirPageComponent,
    ChallengesPageComponent,
    HeaderComponent,
    FooterComponent,
    ChallengeCardComponent,
    ChallengeDetailPageComponent,
    SigninModalComponent,
    SubscribeModalComponent,
    NewsDetailComponent,
    NavComponent,
    PrivacyPageComponent,
    ContactusComponent,
    InfoPageComponent,
    WavesPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [SigninModalComponent, SubscribeModalComponent]
})
export class AppModule { }
