<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0"> OPEN-LAB SRL </h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="https://www.open-lab.com/" target="_blank"><img class="partner-image"
              src="./assets/images/openlab_logo.png"></a>
        </div>
        <div class="partner-description">
          <p>
            Open Lab &egrave; un’azienda nata nel 2001, oggi leader italiano nel settore dei giochi applicati, con sedi
            a Firenze e Milano.
            Open Lab progetta e produce giochi applicati nel settore educativo, salute e ricerca. Lavora con Cambridge
            University, UNESCO, RAI, Università Bocconi, Università Bicocca, IULM,
            numerose farmaceutiche e centri di ricerca e musei producendo design e applicazioni finalizzate a forme
            innovative di apprendimento.
            Pietro Polsinelli, CEO di Open Lab, ha pubblicato il libro “Giochi da prendere sul serio” nel 2016 e una
            seconda edizione rivista nel 2018.
            Nel progetto HUB Open Lab si occupa della creazione di standard di valutazione per l’utilizzo di soluzioni
            di engagement integrate in modo inclusivo con il modello dei bisogni, e della creazione di un modello
            dettagliato per la produzione e valutazione di modelli di engagement utili e compatibili con la
            realizzazione di applicazioni di mediazione tra i dati e gli obiettivi di wellness e prevenzione
          </p>
        </div>

        <button class="button-link">
          <a class="openlab-link" href="https://www.open-lab.com/" target="_blank">
            Vai al sito
          </a>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
