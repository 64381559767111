<div class="header-container">

  <div class="black-line">

    <!-- <a *ngIf="!user" class="login" (click) = "login()">
      <img class="img-user" src="./assets/images/login.svg" referrerpolicy="no-referrer">
      Login
    </a>

    <a *ngIf="user" class="login" [routerLink]="['/dashboard']">
      <img class="img-user" src="{{user.photoURL}}" referrerpolicy="no-referrer"> -->
      <!-- {{user.displayName}} Dashboard -->
      <!-- <span class="name-user">{{user.displayName}}</span> Dashboard
    </a> -->


    <a class="login" href="https://dashboard.smart-touch-id.com/">
      <img class="img-user" src="./assets/images/login.svg" referrerpolicy="no-referrer">Dashboard
    </a>


  </div>

  <div class="white-line">
    <a href="/">
    <img class="logo" src="./assets/images/stid_logo.png">
    </a>

    <div class="title-menu">

      <div class="title">SOLUZIONI INNOVATIVE PER LA RIABILITAZIONE</div>

      <div [ngClass]="(isButtonClicked === false)?'nav-menu':'nav-menu responsive'" >
        <a class="icon" (click)="buttonClicked()">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="24px">
            <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
          </svg>
        </a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/home']">HOME</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/challenges']">CHALLENGE</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/waves']">WAVES</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/project']">HUB</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/partners']">PARTNER</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/news']">NEWS</a>
        <a class="menu-link" [routerLinkActive]="'isActive'" [routerLink]="['/contactus']">CONTATTI</a>
        <a href="https://smart-touch-id.com/en/">
          <img class="flag-icon" src="./assets/images/language/eng.png">
        </a>
      </div>

    </div>

    <div class="space" style="width: 150px; height: 100%;">
    </div>

  </div>
  <div class="yellow-line"></div>
  <br>
</div>
