import { StidUser } from './../../../model/stidUser';

import { ChallengeJson } from './../../../model/challenge';
import { ChallengeService } from 'src/app/services/challenge.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscribeModalComponent } from '../../subscribe-modal/subscribe-modal.component';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { SigninModalComponent } from '../../signin-modal/signin-modal.component';

@Component({
  selector: 'app-challenge-detail-page',
  templateUrl: './challenge-detail-page.component.html',
  styleUrls: ['./challenge-detail-page.component.scss'],
})
export class ChallengeDetailPageComponent implements OnInit {

  public challenge: ChallengeJson;
  public isLogged: boolean;
  public stidUser: StidUser;
  public userSubscribed = false;
  public moreInfo = false;

  constructor(
    private route: ActivatedRoute,
    private challengeService: ChallengeService,
    private modalService: NgbModal,
    public authService: AuthService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.challengeService.getChallengeById(id).subscribe((challange) => {
      this.challenge = challange[0]
    })
    // this.authService.user.subscribe((user) => {
    //   if (user) {
    //     this.userService.getUserData(user.uid).subscribe(stidUser => {
    //       if (stidUser){
    //         this.stidUser = stidUser;
    //         if (this.challenge) {
    //           this.userService.isUserSubscribed(this.stidUser.id, this.challenge.id).subscribe(is => this.userSubscribed = is);
    //         }
    //       }
    //     });
      //   this.isLogged = true;
      // } else {
      //   this.isLogged = false;
      // }
    // });
    // this.challengeService.getChallengeById(id).subscribe((challenge) => {
    //   this.challenge = challenge[0];
    //   if (!this.challenge) {
        // this.challengeService.getChallengeApiById(id).subscribe((challenge) => {
        //   this.challenge = challenge[0];
        //   console.log(this.challenge);
        //    });
      }
    // });
  // }

  // public login(): void{
  //   this.authService.loginWithGoogle().subscribe(userData => {
  //     this.userService.getUserData(userData.user.uid).subscribe(stidUser => {
  //       if (!stidUser){
  //         const modalRef = this.modalService.open(SigninModalComponent, {size: 'lg'});
  //         modalRef.componentInstance.user = userData.user;
  //       }
  //     });
  //   },
  //   err => {
  //     console.log(err);
  //   });
  // }

  // public subscribe(): void {

  //   if (this.isLogged) {
  //     const modalRef = this.modalService.open(SubscribeModalComponent, {size: 'lg'});
  //     modalRef.componentInstance.user = this.stidUser;
  //     modalRef.componentInstance.challenge = this.challenge;
  //   }else{
  //     this.login();
  //   }
  // }

  public subscribe(): void {
      const modalRef = this.modalService.open(SigninModalComponent, {size: 'lg'});
  }

  public showMore(): void {
    this.moreInfo = true;
  }
}
