import { ChallengeJson } from './../../model/challenge';
import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-challenge-card',
  templateUrl: './challenge-card.component.html',
  styleUrls: ['./challenge-card.component.scss']
})
export class ChallengeCardComponent implements OnInit {


  public isOpen: boolean;
  public openString: string;
  public openDate: string;
  // tslint:disable-next-line: variable-name
  private _challenge: ChallengeJson;

  @Input()
  get challenge(): ChallengeJson {return this._challenge; }
  set challenge(challenge: ChallengeJson){
    this._challenge = challenge;
    this.checkdate();
  }

  constructor() { }

  ngOnInit(): void {
  }

  public checkdate(): void{

    // TODO Rimuovere e ripristinare il codice commentato sotto
    // this.isOpen = true;
    // this.openString = "La Challenge è aperta"
    //

    this.isOpen = false;
    this.openString = "La challenge è chiusa";

    // NON CANCELLARE, leggere commento precedente

    // const today = new Date();
    // if (today.getTime() < this.challenge.startDate.seconds * 1000) {
    //   this.isOpen = false;
    //   this.openString = 'La challenge si aprirà  ' ;
    // } else if (today.getTime() > this.challenge.endDate.seconds * 1000) {
    //   this.isOpen = false;
    //   this.openString = 'La challenge è terminata  ';
    // }
    // else {
    //   this.isOpen = true;
    //   this.openString = 'La challenge si è aperta  ';
    // }

    // this.openDate = formatDate(new Date(this.challenge.startDate.seconds * 1000), 'fullDate', 'it');
  }
}
