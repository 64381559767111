import { Competence } from './../model/competence';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CompetenceService {
  // public competences: BehaviorSubject<any[]>;
  // public competencesUrl = './assets/settings/competences.json'

  // constructor(private firestore: AngularFirestore,private http: HttpClient) {
  //   this.competences = new BehaviorSubject(null);
  //   this.getAllCompetences();
  // }

  // // public getAllCompetences(): void {
  // //   this.firestore
  // //     .collection<Competence>('competencies')
  // //     .snapshotChanges()
  // //     .subscribe((snapRoles) => {
  // //       const tempCompetences = snapRoles.map<Competence>((snap) => {
  // //         const competence = snap.payload.doc.data();
  // //         competence.id = snap.payload.doc.id;

  // //         return competence;
  // //       });

  // //       let categories = [
  // //         ...new Set(
  // //           tempCompetences.map((competence) =>
  // //             JSON.stringify({
  // //               name: competence.groupName,
  // //               number: competence.groupNumber,
  // //             })
  // //           )
  // //         ),
  // //       ]
  // //         .map((category) => JSON.parse(category))
  // //         .sort((c1, c2) => c1.number - c2.number);

  // //       categories = categories.map((category) => {
  // //         category.list = tempCompetences
  // //           .filter((competence) => competence.groupNumber === category.number)
  // //           .sort((c1, c2) => c1.groupOrder - c2.groupOrder);
  // //         return category;
  // //       });

  // //      // console.log('comcat', JSON.stringify(categories));

  // //       this.competences.next(categories);
  // //     });
  // // }

  // public getAllCompetences(): Observable<any[]> {

  //   return this.http.get<any[]>(this.competencesUrl);


  //     //   let categories = [
  //     //     ...new Set(
  //     //       tempCompetences.map((competence) =>
  //     //         JSON.stringify({
  //     //           name: competence.groupName,
  //     //           number: competence.groupNumber,
  //     //         })
  //     //       )
  //     //     ),
  //     //   ]
  //     //     .map((category) => JSON.parse(category))
  //     //     .sort((c1, c2) => c1.number - c2.number);

  //     //   categories = categories.map((category) => {
  //     //     category.list = tempCompetences
  //     //       .filter((competence) => competence.groupNumber === category.number)
  //     //       .sort((c1, c2) => c1.groupOrder - c2.groupOrder);
  //     //     return category;
  //     //   });

  //     //  // console.log('comcat', JSON.stringify(categories));

  //     //   this.competences.next(categories);
  //     // });
  // }

  // public getCompetenceById(id: string): Competence {
  //   for (const categories of this.competences.value) {
  //     for (const comp of categories.list) {
  //       if ( comp.id === id) {
  //         return comp;
  //       }
  //     }
  //   }

  //   return null;
  // }
}
