<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">ETT S.p.A.</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="https://ettsolutions.com/" target="_blank"><img class="partner-image"
              src="./assets/images/ett_logo.png"></a>
        </div>
        <div class="partner-description">
          <p>
            ETT S.p.A. è un’industria digitale e creativa internazionale specializzata in innovazione tecnologica e
            culturale per la creazione di esperienze immersive e
            coinvolgenti che uniscono design interattivo e storytelling con le tecnologie più avanzate.
          </p>
        </div>

        <button class="button-link">
          <a class="ett-link" href="https://ettsolutions.com/" target="_blank">
            Vai al sito
          </a>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
