import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waves-page',
  templateUrl: './waves-page.component.html',
  styleUrls: ['./waves-page.component.scss']
})
export class WavesPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
