import { Role } from './../model/role';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleService {

  public roles: BehaviorSubject<any[]>;

  constructor(private firestore: AngularFirestore) {
    this.roles = new BehaviorSubject(null);
    this.getAllRoles();
  }

  public getAllRoles(): void {
    this.firestore.collection<Role>('roles')
      .snapshotChanges()
      .subscribe((snapRoles) => {
        const tempRoles = snapRoles.map<Role>((snap) => {
          const role = snap.payload.doc.data();
          role.id = snap.payload.doc.id;
          return role;
        });

        let categories = [
          ...new Set(
            tempRoles.map((role) =>
              JSON.stringify({ name: role.groupName, number: role.groupNumber })
            )
          ),
        ]
          .map((category) => JSON.parse(category))
          .sort((c1, c2) => c1.number - c2.number);


        categories = categories.map(category => {
          category.list = tempRoles.filter(
            (role) => (role.groupNumber === category.number)).sort((r1, r2) => r1.groupOrder - r2.groupOrder);
          return category;
        });

        this.roles.next(categories);


      });
  }

  public getRoleById(id: string): Role {
    return this.roles.value.filter(role => role.id === id)[0] ? this.roles.value.filter(role => role.id === id)[0] : null;
  }
}
