<div class="app-container">

  <app-header *ngIf="router.url !== '/dashboard'" class="header-content"></app-header>

  <div class="page-content">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="router.url !== '/dashboard'" class="footer-content"></app-footer>

</div>
