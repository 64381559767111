import { Interest } from './../model/interest';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterestService {
  public interests: BehaviorSubject<any[]>;

  constructor(private firestore: AngularFirestore) {
    this.interests = new BehaviorSubject(null);
    this.getAllInterests();
  }

  public getAllInterests(): void {
    this.firestore
      .collection<Interest>('interests')
      .snapshotChanges()
      .subscribe((snapRoles) => {
        const tempInterests = snapRoles.map<Interest>((snap) => {
          const role = snap.payload.doc.data();
          role.id = snap.payload.doc.id;
          return role;
        });

        let categories = [
          ...new Set(
            tempInterests.map((interest) =>
              JSON.stringify({
                name: interest.groupName,
                number: interest.groupNumber,
              })
            )
          ),
        ]
          .map((category) => JSON.parse(category))
          .sort((c1, c2) => c1.number - c2.number);

        categories = categories.map((category) => {
          category.list = tempInterests
            .filter((interest) => interest.groupNumber === category.number)
            .sort((i1, i2) => i1.groupOrder - i2.groupOrder);
          return category;
        });

        // console.log('intcat', JSON.stringify(categories));

        this.interests.next(categories);
      }, error => console.error('error', error)
      );
  }

  public getInterestById(id: string): Interest {

    for (const categories of this.interests.value) {
      for (const interest of categories.list) {
        if ( interest.id === id) {
          return interest;
        }
      }
    }

    return null;
  }
}
