<div class="page-container">
  <div class="message">
    <h3>Qualcosa è andato storto,</h3>
    <h4>la pagina non esiste!</h4>
  </div>
  <button class="button-link">
    <a class="home-link" href="/home">
      Torna alla Home
    </a>
  </button>
</div>
