import { UserService } from './../../../services/user.service';
import { NewsService } from './../../../services/news.service';
import { NewsJson, NewsApi, NewsApiResponse } from './../../../model/news';
import { Component, OnInit } from '@angular/core';
import { ChallengeService } from 'src/app/services/challenge.service';
import { ChallengeJson } from 'src/app/model/challenge';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SigninModalComponent } from '../../signin-modal/signin-modal.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  public challenges: ChallengeJson[];

  public news: NewsJson[];
  public newsApi: NewsApi[];

  public isLogged = true;

  constructor(
    private challengeServ: ChallengeService,
    private newsServ: NewsService,
    private modalService: NgbModal,
    public authService: AuthService,
    public userService: UserService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    });
  }

  ngOnInit(): void {

    this.challengeServ.getAllChallenges().subscribe((challanges) => {
      this.challenges = challanges;
    })

    // this.challengeServ.getAllChallengesApi().subscribe((challeges) => {
    //   this.challenges = challeges;
    // });

    this.newsServ.getAllNews().subscribe((news) => {
      news.sort((a , b) => b.date.seconds - a.date.seconds);
      this.news = news.filter((n, i) => i < 6);
      // this.newsApi = news.filter((n, i) => i < 6);
    });
  }

  // public login(): void{
  //   this.authService.loginWithGoogle().subscribe(userData => {
  //     this.userService.getUserData(userData.user.uid).subscribe(stidUser => {
  //       if (!stidUser){
  //         const modalRef = this.modalService.open(SigninModalComponent, {size: 'lg'});
  //         modalRef.componentInstance.user = userData.user;
  //       }
  //     });
  //   },
  //   err => {
  //     console.log(err);
  //   });
  // }

  public login(): void {
    const modalRef = this.modalService.open(SigninModalComponent, {
      size: 'lg',
    });
  }

  public getShortText(text: string, words: number): string{
    return text.split(' ').slice(0, words).join(' ') + '...';
  }
}
