import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  public getInterests(): Observable<any[]>{
    return this.http.get<any[]>('./assets/settings/interests.json');
  }

  public getCompetencies(): Observable<any[]>{
    return this.http.get<any[]>('./assets/settings/competencies.json');
  }

  public getRoles(): Observable<any[]>{
    return this.http.get<any[]>('./assets/settings/roles.json');
  }
}
