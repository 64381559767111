<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
         <h3 class="m-0">FONDAZIONE DON CARLO GNOCCHI – ONLUS</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
        <a href="https://www.dongnocchi.it/" target="_blank"><img class="partner-image" src="./assets/images/dongnocchi_logo.png"  ></a>
      </div>
      <div class="partner-description">
        <p>
          La Fondazione Don Carlo Gnocchi - ONLUS è una fondazione privata no-profit costituita nel 1952 e successivamente riconosciuta come IRCCS,
          Istituto di Ricovero e Cura a Carattere Scientifico, dal Ministero della Salute negli anni 90.
          La Fondazione si articola in 28 centri distribuiti in 9 regioni e occupa circa 4.000 persone. FDG sul territorio lombardo annovera un IRCCS e 11 Centri.
          Nelle Sedi sono presenti ambulatori dedicati alla diagnosi e cura dei pazienti anziani fragili (UVA ed UVG) ed unità di riabilitazione per gli stessi (regime di degenza, a ciclo diurno continuo e ambulatoriale)
          ove vengono trattati i pazienti con programmi di riabilitazione cognitiva sia mediante approcci convenzionali che sperimentali.
          Nel particolare, c/o l’IRCCS di Milano il Centro Avanzato di Diagnostica e Terapia Riabilitativa (CADiTeR)
          ha introdotto e sperimentato innovativi protocolli di teleriabilitazione per seguire i pazienti fino al domicilio.
        </p>
        <p>
          L’IRCCS S. Maria Nascente (MI), Coordinatore della proposta e uno dei 28 centri della <strong>FDG</strong>,
          la più grande rete di centri italiani dedicati alla riabilitazione. In quanto IRCCS,
          ha accesso a finanziamenti del Ministero della Salute e finalizzati all’innovazione e alla ricerca in campo biomedico e delle tecnologie avanzate per il miglioramento della salute.
        </p>
      </div>

        <button class="button-link">
          <a class="dongnocchi-link" href="https://www.dongnocchi.it/" target="_blank">
            Vai al sito
          </a>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
