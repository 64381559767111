import { Component, OnInit } from '@angular/core';
import { ChallengeJson } from 'src/app/model/challenge';
import { ChallengeService } from 'src/app/services/challenge.service';

@Component({
  selector: 'app-challenges-page',
  templateUrl: './challenges-page.component.html',
  styleUrls: ['./challenges-page.component.scss']
})
export class ChallengesPageComponent implements OnInit {

  public challenges: ChallengeJson[];

  constructor(
    private challengeServ: ChallengeService
  ) { }

  ngOnInit(): void {

    this.challengeServ.getAllChallenges().subscribe(challanges => {
      this.challenges = challanges;
    })

    // this.challengeServ.getAllChalleges().subscribe(challeges => {
    //   this.challenges = challeges;
      // this.challengeServ.getAllChallengesApi().subscribe(challenges => {
      //   this.challenges = challenges;
      // })
    // });
  }
}
