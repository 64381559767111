import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsJson, NewsApiResponse, NewsApi } from '../model/news';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public newsUrl = './assets/settings/news.json';
  public newsApi = 'http://dashboard-smart-touch-id.democontent.it/api/HUB/getValidNews';

  private newsJson: NewsJson;

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient
  ) {}

  public getAllNews(): Observable<NewsJson[]> {
    return this.http.get<NewsJson[]>(this.newsUrl);
  }

  // public getAllNewsApi(): Observable<NewsJson[]> {
  //   return this.http.get<NewsApiResponse>(this.newsApi).pipe(
  //     map((data) => {
  //       const newsApiArray = [];
  //       data.response.forEach((d) => {
  //         var newsJson = this.parseApi(d);
  //         newsApiArray.push(newsJson);
  //       });

  //       return newsApiArray;
  //     })
  //   );
  // }

  public getNewsById(id: string): Observable<any> {
    return this.http.get<any>(this.newsUrl).pipe(
      map((data) => {
        const newsArray = [];
        data.forEach((d) => {
          if (d.id === id) {
            newsArray.push(d);
          }
        });
        return newsArray;
      })
    );
  }

  // public getNewsApiById(id: string): Observable<any> {
  //   return this.http.get<any>(this.newsApi).pipe(
  //     map((data) => {
  //       const newsArray = [];
  //       data.response.forEach((d) => {
  //         if (d.id === parseInt(id)) {
  //           this.newsJson = this.parseApi(d);
  //           newsArray.push(this.newsJson);
  //         }
  //       });
  //       return newsArray;
  //     })
  //   );
  // }

  private parseApi(data: NewsApi): NewsJson {
    var newsJson: NewsJson = {
      id: data.id,
      title: data.titolo,
      description: data.descrizione,
      abstract: data.descrizione,
      date: {
        seconds: new Date(data.dataInizioValidita).getTime() / 1000,
        nanoseconds: 0,
      },
      url: data.url,
      imageUrl: data.urlImmagine,
    };

    return newsJson;
  }
}
