import { CompetenceService } from './../../services/competence.service';
import { InterestService } from './../../services/interest.service';
import { RoleService } from './../../services/role.service';
import { ChallengeService } from 'src/app/services/challenge.service';
import { SettingsService } from './../../services/settings.service';
import { UserService } from '../../services/user.service';
import { User } from 'firebase';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SigninModalComponent } from '../signin-modal/signin-modal.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Smart&Touch-Id';

  private user: User;

  private sub;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    public router: Router,
    private ccService: NgcCookieConsentService
  ) {
    config.backdrop = 'static';

  }


  ngOnInit(): void {
    // this.sub = this.authService.user.subscribe((user) => {
    //   if (user) {
    //     this.userService.getUserData(user.uid).subscribe((stidUser) => {
    //       if (!stidUser) {
    //         this.authService.logout();
    //       }
    //       if (this.sub) {
    //         this.sub.unsubscribe();
    //       }
    //     });
    //   } else {
    //     this.user = null;
    //     if (this.sub) {
    //       this.sub.unsubscribe();
    //     }
    //   }
    // });
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log('change', event);
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnDestroy(): void{
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
