<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">UNIVERSITA' DELI STUDI DI MILANO - BICOCCA</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="https://www.unimib.it/" target="_blank"><img class="partner-image"
              src="./assets/images/bicocca_logo.png"></a>
        </div>
        <div class="partner-description">
          <p>
            L’Università degli Studi di Milano-Bicocca (UNIMIB; Partner) fondata nel 1998, conta 14 Dipartimenti (otto
            riconosciuti dal MIUR come Dipartimenti di Eccellenza) su 7 aree disciplinari (Economia e Statistica,
            Giurisprudenza, Medicina e Chirurgia, Scienze, Psicologia, Scienze della Formazione, e Sociologia) e oltre
            30000 studenti (a.a. 2019/2020). È costituita in un campus di 21 edifici, tutti nell’omonimo quartiere milanese,
            a eccezione della sede della facoltà di Medicina e Chirurgia nei pressi dell’Ospedale San Gerardo (Monza),
            polo ospedaliero universitario.
          </p>
          <p>
            Unimib partecipa a Smart&Touch-ID con i Dipartimenti di: Scienze Umane per la Formazione “Riccardo Massa”
            (Centro Studi per le Scienze della Comunicazione "Luigi Anolli" (CESCOM), di Informatica, Sistemistica e
            Comunicazione (Mind Lab; Software Architecture Lab; MultiMedia Signal Processing Lab), di Psicologia
            (Laboratorio di Psicologia e Comunicazione per la Salute), e di Sociologia (Centro di Ricerca “Benessere
            Digitale”).
          </p>
          <p>
            La sinergia delle expertise a disposizione del progetto includono la profilazione dei bisogni dei pazienti,
            la modellizzazione del benessere (componenti e predittori) e dell’engagement nonché il design, la misurazione e
            la validazione di tecnologie digitali innovative per la promozione del benessere e dell’engagement in
            teleriabilitazione, con focus specifico sulle patologie croniche. Con un background robusto in architetture
            software e data analytics, completano il quadro le competenze di eccellenza in tecniche di machine learning
            e supporto alle decisioni, di affective computing, oltre che di self-healing e di self-repair (pervasive
            healthcare).
          </p>
        </div>
        <button class="button-link">
          <a class="bicocca-link" href="https://www.unimib.it/" target="_blank">
            Vai al sito
          </a>
        </button>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
