<div class="challenge-container">
  <div class="top-container">
    <img class="challenge-image" src="{{challenge.imageUrl}}" alt="">
    <div class="image-filter"></div>
    <div class="title-container">
      <div class="challenge-label">Challenge</div>
      <div class="title-label">{{challenge.title}}</div>
      <div class="payoff-label">{{challenge.payoff}}</div>
    </div>
  </div>
  <div class="yellow-bar">
    <div class="info-container">
      <svg *ngIf="isOpen" class="lock-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  viewBox="0 0 24 24"><path d="M18 1C15.24 1 13 3.24 13 6V8H4C2.9 8 2 8.89 2 10V20C2 21.11 2.9 22 4 22H16C17.11 22 18 21.11 18 20V10C18 8.9 17.11 8 16 8H15V6C15 4.34 16.34 3 18 3C19.66 3 21 4.34 21 6V8H23V6C23 3.24 20.76 1 18 1M10 13C11.1 13 12 13.89 12 15C12 16.11 11.11 17 10 17C8.9 17 8 16.11 8 15C8 13.9 8.9 13 10 13Z" /></svg>
      <svg *ngIf="!isOpen" class="lock-icon"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  viewBox="0 0 24 24"><path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" /></svg>
      <span>{{this.openString}} <strong>{{this.openDate}}</strong></span>
    </div>
  </div>
</div>
