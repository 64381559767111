<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
         <h3 class="m-0">PRIVACY POLICY</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p><u>Informativa ai sensi dell’art. 13 del Regolamento UE 679/2016</u></p>
       <p>Gentile utente, in conformità all’articolo 13 del Regolamento Generale per la Protezione dei Dati UE 679/2016 (di seguito, anche per brevità, il “RGPD”), desideriamo informarLa sulle modalità, finalità comunicazione, diffusione e diritti dei Suoi dati personali richiesti per la registrazione al sito internet e necessari per la partecipazione al progetto “<strong><em><u>SMART&Touch-ID: l’HUB per la creazione di sistemi riabilitativi innovativi-SMART, connessi a bisogni e territorio-Touch, individualizzati-ID e sostenibili</u></em></strong>”  (di seguito, anche per brevità, il “Progetto”).</p>

        <h3>CATEGORIE DI DATI PERSONALI TRATTATI</h3>
        <p>I dati personali necessari, richiesti sia per l’erogazione dei servizi inerenti alla Sua registrazione al sito internet e sia per la Sua partecipazione al “Progetto”, saranno i seguenti dati personali: Nome, Cognome, indirizzo e-mail, Ente di afferenza/appartenenza e dati inerenti ai Suoi interessi e alle Sue competenze.</p>

        <h3>CONTITOLARI DEL TRATTAMENTO e ALTRI SOGGETTI</h3>
        <p>I Suoi dati personali saranno trattati dai seguenti ConTitolari del Trattamento (individuati partner nel “Progetto”):</p>

          <ul>
            <li>FONDAZIONE DON CARLO GNOCCHI - ONLUS, C.F. 04793650583, P.IVA 12520870150, sede legale in Piazzale Morandi 6, Milano</li>
            <li>UNIVERSITÀ CARLO CATTANEO - LIUC, C.F./P.IVA 02015300128, sede legale in Corso Matteotti 22, Castellanza (VA)</li>
            <li>UNIVERSITÀ DEGLI STUDI DI MILANO - BICOCCA, C.F./P.IVA 12621570154, sede legale in Piazza dell'Ateneo Nuovo 1, Milano</li>
            <li>ETT S.p.A., C.F./P.IVA 03873640100, con sede legale in via Sestri 37, 16154 Genova</li>
            <li>IRCCS Istituto Centro San Giovanni di Dio Fatebenefratelli di Brescia, C.F./P.IVA 01647730157, con sede legale in Via Pilastroni, 4, Brescia</li>
            <li>ASTIR S.r.l., C.F./P.IVA 05484570964, con sede legale in via G. B. Pirelli, 30, Milano</li>
            <li>OPEN LAB S.r.l., C.F./P.IVA 05095280482, con sede legale in via Venezia 18/b, Firenze</li>
          </ul>

        <p>In riferimento ai dati personali richiesti per la Sua registrazione al sito internet, i ConTitolari del Trattamento hanno individuato il partner tecnologico ETT S.p.A., C.F./P.IVA 03873640100, con sede legale in via Sestri 37, 16154 Genova.</p>

        <h3>FINALITA’ DEL TRATTAMENTO</h3>
        <p>I Suoi dati personali saranno trattati dai ConTitolari del trattamento solo con il Suo consenso ed in conformità con le disposizioni previste dal RGPD, al fine di rendere possibile la partecipazione alle attività del “Progetto” e alle specifiche challenge rese disponibili per la durata del “Progetto” oltre all’invio di news e/o comunicazioni del “Progetto”.</p>

        <h3>BASE GIURIDICA DEL TRATTAMENTO</h3>
        <p>Il conferimento dei Dati Personali per la finalità sopra descritta è facoltativo e soggetto al Suo esplicito consenso, in conformità all’art.6, lettera a) del RGPD, e potrà essere revocato in qualsiasi momento, senza pregiudicare in alcun modo il trattamento effettuato dai ConTitolari prima di tale revoca.</p>

        <h3>MODALITA’ DI TRATTAMENTO</h3>
        <p>Il trattamento dei suoi Dati Personali sarà improntato ai principi di correttezza, liceità e trasparenza in modo da garantirne la sicurezza mediante operazioni manuali e/o informatizzate.</p>
        <p>Le operazioni di trattamento saranno effettuate mediante strumenti manuali, informatici e telematici, con logiche strettamente correlate alle finalità sopra evidenziate e, comunque, esclusivamente da soggetti debitamente nominati responsabili o incaricati, opportunamente istruiti e resi edotti dei vincoli imposti dalla legge, nonché mediante l’impiego di misure di sicurezza atte a garantire la tutela della Sua riservatezza e ad evitare i rischi di perdita o distruzione, di accessi non autorizzati, di trattamenti non consentiti o non conformi alle finalità di cui sopra.</p>

        <h3>DIFFUSIONE, COMUNICAZIONE E SOGGETTI CHE ACCEDONO AI DATI</h3>
        <p>I dati personali raccolti non potranno essere diffusi o ceduti a terzi, salve le ipotesi previste per legge.</p>
        <p>È fatta salva, in ogni caso, la comunicazione di dati a società espressamente incaricate a svolgere determinate prestazioni nell’ambito dell’attività svolta dai ConTitolari per il “Progetto” e/o, in generale, in suo favore, che opereranno in qualità di titolari autonomi e/o responsabili del trattamento, nonché la comunicazione e/o diffusione di dati richiesti, in conformità alla legge, da forze di polizia, dall’autorità giudiziaria, da organismi di informazione e sicurezza o da altri soggetti pubblici per finalità di difesa o di sicurezza dello Stato o di prevenzione, accertamento o repressione dei reati.</p>

        <h3>DURATA DEL TRATTAMENTO E CONSERVAZIONE DEI DATI</h3>
        <p>I Suoi Dati Personali saranno trattati dai ConTitolari per tutta la durata necessaria al raggiungimento delle finalità del “Progetto”, dopodiché verranno conservati unicamente in esecuzione degli obblighi di legge vigenti in materia, per finalità di tipo amministrativo e/o per far valere o difendere un proprio diritto, in caso di contenziosi e precontenziosi. I Suoi dati Personali saranno conservati a cura del partner tecnologico ETT S.p.A., C.F./P.IVA 03873640100, con sede legale in via Sestri 37, 16154 Genova.</p>

        <h3>TRASFERIMENTO DEI DATI ALL’ESTERO</h3>
        <p>I Suoi Dati Personali saranno trattati all’interno del territorio dell’Unione Europea.</p>
        <p>Qualora per questioni di natura tecnica e/o operativa per la migliore operatività del “Progetto” si rendesse necessario avvalersi di soggetti ubicati al di fuori dell’Unione Europea, oppure si rendesse necessario trasferire alcuni dati raccolti verso sistemi tecnici e servizi localizzati al di fuori dell’area dell’Unione Europea, saranno adottate tutte le cautele necessarie al fine di garantire la più totale protezione dei dati personali basando il trasferimento su decisioni di adeguatezza dei paesi terzi destinatari, su garanzie espresse del soggetto terzo destinatario e sull’adozione di norme vincolanti d’impresa.</p>

        <h3>DIRITTI DELL’INTERESSATO</h3>
        <p>In relazione ai trattamenti descritti nella presente informativa Lei potrà esercitare i suoi diritti ai sensi degli artt. 15 e ss. del RGPD, consistenti essenzialmente nel diritto di ricevere dai ConTitolari informazioni in merito all’esistenza del trattamento dei suoi dati personali, nonché di accedere ai Suoi dati, di ottenerne la rettifica, l’integrazione, l’aggiornamento, la cancellazione o il blocco. Lei, inoltre, avrà il diritto di ottenere una copia dei Suoi dati, la limitazione del trattamento e/o, ancora, di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati e a proporre reclamo alle autorità di controllo competenti, alle condizioni e nei limiti indicati all’art. 13 del GDPR.</p>

        <h3>ESERCIZIO DEI DIRITTI</h3>
        <p>Nei confronti di ciascuno dei ConTitolari del trattamento potrà inviare una comunicazione per esercitare i suoi diritti. I recapiti sono indicati nell’apposita sezione di questa informativa.</p>
        <p>Per esercitare l’eventuale revoca e/o cancellazione dalla registrazione del sito può contattare direttamente il partner tecnologico ETT S.p.A., C.F./P.IVA 03873640100, con sede legale in via Sestri 37, 16154 Genova.</p>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
