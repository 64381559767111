<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">News</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row hidden-md-up">
          <div *ngFor="let news of news" class="col-md-6 col-sm-12">
            <a routerLink="/news/{{news.id}}">
              <div class="card card-news">
                <span class="date-label">{{getFormattedDate(news.date)}}</span>
                <img class="card-img-top img-news" src="{{news.imageUrl}}" onerror="this.src='./assets/images/placeholder.png'" />
                 <div class="card-news-body">
                  <span class="card-title">
                   {{news.title}}
                  </span>
                  <div class="yellow-line"></div>
                  <span class="card-text">
                    {{getShortText(news.abstract, 40)}}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </ng-template>

      <!-- <ng-template ngbPanelContent>
        <div class="row hidden-md-up">
          <div *ngFor="let news of newsApi" class="col-md-6 col-sm-12">
            <a routerLink="/news/{{news.id}}">
              <div class="card card-news">
                <span class="date-label">{{getFormattedDate(news.date)}}</span>
                <img class="card-img-top img-news" src="{{news.imageUrl}}" />
                 <div class="card-news-body">
                  <span class="card-title">
                   {{news.titolo}}
                  </span>
                  <div class="yellow-line"></div>
                  <span class="card-text">
                    {{getShortText(news.abstract, 40)}}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </ng-template> -->

    </ngb-panel>
  </ngb-accordion>
</div>
