<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
         <h3 class="m-0">Partner</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="py-5">
          <div class="container">
            <div class="row hidden-md-up">
              <div class="col-md-6">
                <a routerLink="/dongnocchi">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/dongnocchi_logo.png" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-text">
                        FONDAZIONE DON CARLO GNOCCHI – ONLUS
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/bicocca">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/bicocca_logo.png" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">UNIVERSIT&Agrave; DEGLI STUDI DI MILANO-BICOCCA</h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/liuc">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/liuc_logo.jpg" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">
                        UNIVERSIT&Agrave; CARLO CATTANEO – LIUC
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/irccs">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/FBF.png"/>
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">
                        IRCCS CENTRO SAN GIOVANNI DI DIO - FATEBENEFRATELLI
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/ett">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/ett_logo.png" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">ETT S.p.A</h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/astir">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/astir_logo.png" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">ASTIR S.r.L </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-6">
                <a routerLink="/openlab">
                  <div class="card card-partner">
                    <img class="card-img-top img-partner"
                      src="./assets/images/openlab_logo.png" />
                    <div class="card-body partner-card-body">
                      <h5 class="card-title">OPEN LAB S.r.L.</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>


