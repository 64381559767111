<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
         <h3 class="m-0">Challenge</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
          <div *ngFor="let challenge of challenges" class="challenge-cell" routerLink="/challenge/{{challenge.id}}">
            <app-challenge-card
            class="challenge-card"
            [challenge] = "challenge"
            ></app-challenge-card>
            <!-- <div class="challenge-description">
              {{challenge.shortDescr}}
            </div> -->
          </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
