<div class="page-container">
    <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
      <ngb-panel id="panel-0" cardClass="accordion-card">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-center">
            <h3 class="m-0">WAVES</h3>
          </div>
        </ng-template>
  
        <ng-template ngbPanelContent>
          <div class="project-description">
            <img src="./assets/images/AdobeStock_353083304 [Convertito].jpg" alt="Waves" class="waves-image">
            <p>
                Vivere con una malattia cronica significa governare quotidianamente l’incertezza. È un’esperienza complessa, a volte anche difficile. Unica per ciascuno.                
            </p>
            <p>
                Noi ci occupiamo di riabilitazione, e abbiamo bisogno della tua esperienza unica. L’abbiamo chiamata onda (wave, in inglese).
            </p>
            <p>
                Perché la nostra idea è che, solo lasciando emergere tutte le onde (waves), ci si può orientare nel mare delle esperienze con le malattie croniche, perché 
                si può fare una riabilitazione che tocca la salute e il benessere delle persone solo quando  l’eccellenza clinica si combina con i bisogni di ciascuno.
            </p>
            <p>
                Raccontaci, allora, la tua esperienza unica e rispondi alle domande che abbiamo preparato per te.
            </p>
            <p>
              Il questionario è anonimo e non sarà necessaria alcuna registrazione. Tutti i dati raccolti saranno trattati in forma aggregata e anonima. 
            </p>
            <!-- <p>
                Ti aspettiamo!
            </p> -->

            <div class="button-container">
              <a href="https://unimib.qualtrics.com/jfe/form/SV_4Oz6BX2xsfT9MQ6" target="_blank">
                <button class="btn btn-open subscribe-btn">
                  Avvia il questionario
                </button>
              </a>
              <br>
              <p style="font-size: x-large; margin-top: 16px;">Vuoi rimanere aggiornato sui risultati della Wave? <strong><a href="https://dashboard.smart-touch-id.com/" target="_blank">Iscriviti ad HUB</a></strong>!</p>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  