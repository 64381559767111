<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0" style="text-align: center;">IRCCS CENTRO SAN GIOVANNI DI DIO – FATEBENEFRATELLI</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="https://www.fatebenefratelli.it/" target="_blank"><img class="partner-image"
              src="./assets/images/FBF.png"></a>
        </div>
        <div class="partner-description">
          <p>
            L’IRCCS Istituto Centro San Giovanni di Dio Fatebenefratelli di Brescia &egrave; un centro leader nella
            ricerca e cura delle malattie psichiatriche e della malattia di Alzheimer.
            L’istituto &egrave; stato fondato nel 1882 e nel 1996 &egrave; stato riconosciuto Istituto di Ricovero e
            Cura a Carattere Scientifico (IRCCS) dal Ministero della Salute.
            Il Centro svolge un’intensa attivit&agrave; di ricerca nel campo delle neuroscienze. I programmi di ricerca
            sono un aspetto essenziale dell’assistenza:
            permettono di scoprire nuove cure e trattamenti nell’interesse di tutta la comunit&agrave;.
          </p>
          <p>
            L’IRCCS Istituto Centro San Giovanni di Dio Fatebenefratelli ha stabilito rapporti collaborativi con
            qualificati centri europei e statunitensi, dimostrando capacità di operare in rete.
          </p>
          <p>
            L’IRCCS Istituto Centro San Giovanni di Dio Fatebenefratelli cura ogni anno oltre seimila pazienti nei suoi
            ambulatori,
            gestisce dieci Unità/Laboratori di ricerca e cinque Servizi di Ricerca oltre ad avere una elevata capacità
            di degenza nelle sue strutture sia in psichiatria, sia nella riabilitazione Alzheimer.
          </p>
        </div>

        <button class="button-link">
          <a class="irccs-link" href="https://www.fatebenefratelli.it/" target="_blank">
            Vai al sito
          </a>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
