<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">ASTIR</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="http://www.astir.com" target="_blank"> <img class="partner-image"
              src="./assets/images/astir_logo.png"></a>
        </div>
        <div class="partner-description">
          <p>
            Astir è una società di consulenza e sviluppo di soluzioni tecnologiche che, dalla sua fondazione nel 2006, è
            attiva in numerosi progetti e servizi di innovazione in ambito sanitario, oltre che in quelli industriali e
            della pubblica amministrazione.
          </p>
          <p>
            Astir è un importante fornitore nella filiera dei servizi erogati da Regione Lombardia nel mondo delle reti
            informatiche per la sanità collaborativa,
            con soluzioni software che vanno dall’organizzazione clinica e logistica dell’assistenza inter-ospedaliera
            alla tele-consulenza,
            fino al monitoraggio epidemiologico in scala regionale sui dati prodotti dalle risorse di emergenza-urgenza
            ospedaliera.
          </p>
          <p>
            Nel settore della telemedicina, Astir realizza soluzioni che vedono il paziente quale attore in prima linea,
            come i registri nazionali di malattie rare neuromuscolari o il software per dispositivi mobili
            per la riabilitazione cognitiva domiciliare di pazienti affetti da demenza,
            progettato assieme al Centro Avanzato di Diagnostica e Terapia Riabilitativa della Fondazione Don Gnocchi.
          </p>
          <p>
            Nel progetto Smart&Touch-ID, Astir renderà disponibili le proprie tecnologie digitali per definire e testare
            i processi dell’HUB che orchestreranno l’incontro tra i bisogni dei pazienti
            e l’offerta di soluzioni innovative, passando attraverso la validazione tecnologica multidimensionale.
            Astir, inoltre, guiderà le attività del partenariato nella definizione di un piano di sfruttamento dei
            risultati di progetto.
          </p>
        </div>

        <button class="button-link">
          <a class="astir-link" href="http://www.astir.com" target="_blank">
            Vai al sito
          </a>
        </button>

      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
