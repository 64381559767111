import { NewsService } from './../../../services/news.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsJson } from 'src/app/model/news';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
  public news: NewsJson;

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log('id', id);

    this.newsService.getNewsById(id).subscribe((news) => {
      this.news = news[0]
      // if (!this.news) {
      //   this.newsService.getNewsApiById(id).subscribe((news) => (this.news = news[0]));
      // }
    });
  }

  public getFormattedDate(date: any): string {
    return formatDate(new Date(date.seconds * 1000), 'fullDate', 'it');
  }
}
