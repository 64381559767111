<div class="page-container">

  <div class="info-container">
    <div class="info-image-container">
      <img class="info-image" src="./assets/images/stid_logo.png">
    </div>
    <div class="info-text">
      <div class="info-title">
        IL NOSTRO OBIETTIVO
      </div>
      <div class="info-description">
        <p>
          Promuovere la salute e il benessere dei pazienti con disabilità cronica attraverso
          tecnologie abilitanti il percorso di cura.
        </p>
        <div class="info-title">
          L'IDEA
        </div>
        <p>
          Supportare la creazione di percorsi di cura con soluzioni tecnologiche...
        </p>
        <p>
          ...<strong>SMART</strong> di creatività e innovazione.<br>
          ...<strong>TOUCH</strong> in linea con i bisogni di salute e di benessere delle persone con disabilità croniche.<br>
          ...<strong>ID</strong> in modo personalizzato e scientificamente validato.<br>
        </p>
      </div>
    </div>
  </div>

  <div class="info-container">
    <div class="info-image-container">
      <img class="info-image" src="./assets/images/heart_static.svg">
    </div>
    <div class="info-text">
      <div class="info-title">
        IL CUORE DI HUB
      </div>
      <div class="info-description">
        <p>
          SONO le esperienze di vita con la cronicità... e i BISOGNI delle persone che vivono con la cronicità.
        </p>
        <p>
          PERCHÈ le persone con cronicità sono PROTAGONISTE del percorso di cura.
        </p>
        <p>
          Qualunque soluzione tecnologica per la riabilitaziione è costruita INSIEME CON
          le persone con cronicità ed è PROPRIO PER LORO.
        </p>
      </div>
    </div>
  </div>

  <div class="info-container">
    <div class="info-image-container">
      <img class="info-image" src="./assets/images/plant_static.svg">
    </div>
    <div class="info-text">
      <div class="info-title">
        IL TERRENO DI HUB
      </div>
      <div class="info-description">
        <p>
          È la TUA professionalità nel proporre percorsi di benessere e di cura per la cronicità.
        </p>
        <p>
          PERCHÈ in HUB trovano voce le ESIGENZE nel processo di management delle soluzioni tecnologiche
          per la cronicità.
        </p>
        <p>
          PERCHÈ HUB lavora per rendere tali soluzioni per la riabilitazione, oltre che efficaci, anche
          SOSTENIBILI, per tutti i tipi di ORGANIZZAZIONE SANITARIA in cui professionisti che partecipano
          ai percorsi di cura oer la cronicità sono PARTE INTEGRANTE dell'ecosistema delle soluzioni per la
          riabilitazione.
        </p>
      </div>
    </div>
  </div>

  <div class="info-container">
    <div class="info-image-container-car">
      <img class="info-image-car" src="./assets/images/car_static.svg">
    </div>
    <div class="info-text">
      <div class="info-title">
        IL MOTORE DI HUB
      </div>
      <div class="info-description">
        <p>
          Le TUE soluzioni tecnologiche sono il <strong>MOTORE di HUB</strong>.
        </p>
        <p>
          PERCHÈ in Hub TROVANO la strada per diventare PARTE INTEGRANTE dei percorsi di cura
          (i pazienti, le aziende, e chi usa le soluzioni, operano INSIEME).
        </p>
        <p>
          Perché potrai progettare nuove soluzioni ANTICIPANDO le traiettorie del mercato.
        </p>
        <p>
          Le persone, gli Enti, gli esperti che lavoreranno con te sono ATTORI della MEDESIMA
          RETE e ne saranno i primi utilizzatori e i primi sponsor.
        </p>
      </div>
    </div>
  </div>

  <div class="info-container">
    <div class="info-image-container">
      <img class="info-image" src="./assets/images/stid_logo.png">
    </div>
    <div class="info-text">
      <div class="info-title">
        COSA FACCIAMO
      </div>
      <div class="info-description">
        <p>
          <strong>Lanciamo SFIDE</strong> (le CHALLENGE di HUB), in cui le aziende possono proporre le loro soluzioni TECNOLOGICHE per la riabilitazione.
        </p>
        <p>
          <strong>Lanciamo WAVES</strong>
        </p>
        <p>
          Facciamo emergere le ESPERIENZE e i BISOGNI delle persone che vivono con una cronicità per PROGETTARE insieme una RIABILITAZIONE di ECCELLENZA. 
          Le esperienze sono le onde – le waves. Non è una semplice raccolta di opinioni. E’ dare corpo e sostanza al mare delle esperienze dei cittadini 
          per trasformare la riabilitazione in funzione di ciò di cui hanno davvero bisogno.
        </p>
        <p>
          Offriamo <strong>MENTORSHIP</strong> sulla soluzione tecnologica, ossia ANALISI DI METODO E OPPORTUNITÀ DI EVOLUZIONE PROGETTUALE del prototipo, rispetto a:
        </p>
        <ul class="tab-text">
          <li style="color:#EF2D23">DESIGN E SVILUPPO</li>
          <li style="color:#9A8CC7">EFFICACIA DI BENESSERE</li>
          <li style="color:orangered">EFFICACIA CLINICA</li>
          <li style="color:#5D953A">SOSTENIBILITÁ ECONOMICA, ORGANIZZATIVA E RISPONDENZA AI CRITERI REGOLATORI</li>
          <!-- <li>Realizzazione di studi di fattibilità/validazione scientifica</li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
