import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { BehaviorSubject, from, Observable} from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: BehaviorSubject<User>;

  constructor(public fireAuth: AngularFireAuth) {
    this.user = new BehaviorSubject(null);
    this.fireAuth.authState.subscribe(user => {
      if (user){
        this.user.next(user);
      } else {
        this.user.next(null);
      }
    });
  }

  isLogged(): Observable<boolean> {
    return this.fireAuth.authState.pipe(
      map(user => user ? true : false)
    );
  }


  public  loginWithGoogle(): Observable<auth.UserCredential>{
    return from(this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider())) ;
  }

  public logout(): void{
    this.fireAuth.signOut();
  }
}
