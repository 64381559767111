import { UserService } from './../../services/user.service';
import { StidUser } from './../../model/stidUser';
import { AuthService } from './../../services/auth.service';
import { auth } from 'firebase/app';
import { Competence } from './../../model/competence';
import { CompetenceService } from './../../services/competence.service';
import { Interest } from './../../model/interest';
import { InterestService } from './../../services/interest.service';
import { SettingsService } from './../../services/settings.service';
import { User } from 'firebase';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss']
})
export class SigninModalComponent implements OnInit {

  @Input() user: User;

  public interests: any;

  public tabIndex = 0;

  public competencies: any;

  public profileForm: FormGroup;

  public checkedInterests = [];

  public checkedCompetencies = [];

  constructor(public activeModal: NgbActiveModal,
              private interestService: InterestService,
              private formBuilder: FormBuilder,
              private competenceService: CompetenceService,
              private authService: AuthService,
              private userService: UserService) {

    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      institution: [''],
      newsSubscribed: [false],
      privacyCheck: [false, Validators.requiredTrue]
    });

    // this.interestService.interests.subscribe(inter => {
    //   //console.log('int', inter);
    //   this.interests = inter;
    // });

    // this.competenceService.competences.subscribe(comp => {
    //   this.competencies = comp;
    // });
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  log(i:any, j:any){
    console.log(i,j);
  }

  public onSubmit(): void {
    this.profileForm.markAllAsTouched();
    console.log(this.profileForm.valid);
    console.warn(this.profileForm.value);
    if (this.profileForm.valid) {
      const stidUser = {} as StidUser;

      stidUser.firstName = this.profileForm.value.firstName;
      stidUser.lastName = this.profileForm.value.lastName;
      stidUser.displayName = this.user.displayName;
      stidUser.id = this.user.uid;
      stidUser.institution = this.profileForm.value.institution;
      stidUser.email = this.user.email;
      stidUser.admin = false;
      stidUser.validator = [];
      stidUser.attachments = [];
      stidUser.biosketch = '';
      stidUser.imageUrl = this.user.photoURL;
      stidUser.newsPermission = this.profileForm.value.newsSubscribed;
      stidUser.phone = '';
      stidUser.subscriptionDate = new Date();

      console.log('stidUser', stidUser);

      // this.userService.registerUser(stidUser, this.checkedInterests, this.checkedCompetencies).subscribe(data => this.activeModal.close(), err => this.close());

    }
  }

  public close(): void{
    this.authService.logout();
    this.activeModal.close();
  }

  interestChecked(interest: Interest): void{
    this.checkedInterests.indexOf(interest) > -1 ?
    this.checkedInterests.splice(this.checkedInterests.indexOf(interest), 1) : this.checkedInterests.push(interest);
  }

  competenceChecked(competence: Competence): void{
    this.checkedCompetencies.indexOf(competence) > -1 ?
    this.checkedCompetencies.splice(this.checkedCompetencies.indexOf(competence), 1) : this.checkedCompetencies.push(competence);
  }

  // onNavChange(changeEvent: NgbNavChangeEvent) {
  //   if (changeEvent.nextId === 6) {
  //     changeEvent.preventDefault();
  //   }
  // }
}
