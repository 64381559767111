import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
  ChallengeApi,
  ChallengeApiResponse,
  ChallengeJson,
} from '../model/challenge';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  private challenges;
  public challengesUrl = './assets/settings/challenge.json';
  public challengesApiUrl =
    'http://dashboard-smart-touch-id.democontent.it/api/HUB/getChallenges';

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  public getAllChallenges(): Observable<ChallengeJson[]> {
    return this.http.get<ChallengeJson[]>(this.challengesUrl)
  }

  // public getAllChallengesApi(): Observable<ChallengeJson[]> {
  //   return this.http.get<ChallengeApiResponse>(this.challengesApiUrl).pipe(
  //     map((data) => {
  //       const challengeApiArray = [];
  //       data.response.forEach((d) => {
  //         var challengeApi = this.parseApi(d);
  //         challengeApiArray.push(challengeApi);
  //       });

  //       return challengeApiArray;
  //     })
  //   )
  // }

  // public getAllChallengesApi(): Observable<any[]> {
  //   return this.http.get<any>(this.challengesApiUrl).pipe(
  //     switchMap((data) => {
  //       const requests = [];

  //       data.response.forEach((d) => {
  //         requests.push(this.http.get<any>(d.urlImmagine, { responseType: 'text' as 'json' }).pipe(
  //             map((url) => this.parseApi(d, url))
  //           ));
  //       });

  //       return forkJoin(requests);
  //       // .pipe(
  //       //   catchError(error => {
  //       //     console.log(error)
  //       //   }));
  //     })
  //   );
  // }

  private parseApi(data: ChallengeApi, url?: string): ChallengeJson {
    var challengeJson: ChallengeJson = {
      id: data.id,
      title: data.titolo,
      payoff: data.payoff,
      shortDescr: data.descrizioneRidotta,
      longDescr: data.descrizioneEstesa,
      techDescr: data.descrizioneTecnica,
      imageUrl: url,
      startDate: {
        seconds: new Date(data.dtInizio).getTime() / 1000,
        nanoseconds: 0,
      },
      endDate: {
        seconds: new Date(data.dtFine).getTime() / 1000,
        nanoseconds: 0,
      },
      type: data.tipo,
    };

    return challengeJson;
  }

  public getChallengeById(id: string): Observable<any> {
    return this.http.get<any>(this.challengesUrl).pipe(
      map((data) => {
        // console.log('challenge', data)
        const challengesArray = [];
        data.forEach(d => {
          if (d.id === id) {
            challengesArray.push(d);
          }
        });
        return challengesArray;
      })
    );
  }

  public getChallengeApiById2(id: string): Observable<any> {
    return this.http.get<any>(this.challengesApiUrl).pipe(
      map((data) => {
        const challengeApiArray = [];
        data.response.forEach((d) => {
          if (d.id === parseInt(id)) {
            d = this.parseApi(d);

            challengeApiArray.push(d);
          }
        });
        return challengeApiArray;
      })
    );
  }

  // public getChallengeApiById(id: string): Observable<any[]> {
  //   return this.http.get<any>(this.challengesApiUrl).pipe(
  //     switchMap((data) => {
  //       const requests = [];

  //       data.response.forEach((d) => {
  //         if (d.id === parseInt(id)) {
  //           requests.push(this.http.get<any>(d.urlImmagine, { responseType: 'text' as 'json' }).pipe(
  //             map((url) => this.parseApi(d, url))
  //           ));
  //         }
  //       });

  //       return forkJoin(requests);
  //       // .pipe(
  //       //   catchError(error => {
  //       //     console.log(error)
  //       //   }));
  //     })
  //   );
  // }

  public getChallengeApiByIdOld(id: string): Observable<any> {
    return this.http.get<any>(this.challengesApiUrl).pipe(
      switchMap((d) => {
        const requests = [];

        requests.push(this.http.get<any>(d.urlImmagine, { responseType: 'text' as 'json' }).pipe(
              map((data) => {
                if (data.id === parseInt(id)) {
                  requests.push(
                    this.http.get<any>(d.urlImmagine, {responseType: 'text' as 'json',}).pipe(
                      map((url) => this.parseApi(data, url))
                    ));
                }
              })
            )
        );

        return forkJoin(requests);
        // .pipe(
        //   catchError(error => {
        //     console.log(error)
        //   }));
      })
    );
  }

  // public getChallengeById(id: string): Observable<Challenge> {
  //   return this.firestore.collection('challenges').doc(id).snapshotChanges().pipe(
  //     map (challenge => {
  //         const data = challenge.payload.data() as Challenge;
  //         data.id = challenge.payload.id;
  //         return data;
  //     })
  //   );
  // }

  public getChallengeWithId(id: string): ChallengeJson {
    for (const cha of this.challenges) {
      if (cha.id === id) {
        return cha;
      }
    }
  }
}

// public populateCompetences(): void{

//   this.settingsService.getCompetencies().subscribe(competencies => {
//     for (let i = 0; i < competencies.length; i++) {
//       const category = competencies[i];
//       for (let j = 0; j < category.subcategories.length; j++) {
//         const competence = category.subcategories[j];
//         const obj = {name: competence, groupNumber: i, groupName: category.category, groupOrder: j};
//         console.log('obj', obj);
//         this.firestore.collection('competencies').add(obj).then(ref => console.log('ref', ref), error => console.log('err', error));
//       }
//     }
//   });

// }

// public populateInterest(): void{

//   this.settingsService.getInterests().subscribe(interests => {
//     for (let i = 0; i < interests.length; i++) {
//       const category = interests[i];
//       for (let j = 0; j < category.subcategories.length; j++) {
//         const interest = category.subcategories[j];
//         const obj = {name: interest, groupNumber: i, groupName: category.category, groupOrder: j};
//         console.log('obj', obj);
//         this.firestore.collection('interests').add(obj).then(ref => console.log('ref', ref), error => console.log('err', error));
//       }
//     }
//   });

// }

// public populateRoles(): void{

//   this.settingsService.getRoles().subscribe(roles => {
//     for (let i = 0; i < roles.length; i++) {
//       const category = roles[i];
//       for (let j = 0; j < category.subcategories.length; j++) {
//         const role = category.subcategories[j];
//         const obj = {name: role, groupNumber: i, groupName: category.category, groupOrder: j};
//         console.log('obj', obj);
//         this.firestore.collection('roles').add(obj).then(ref => console.log('ref', ref), error => console.log('err', error));
//       }
//     }
//   });

// }
