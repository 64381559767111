<div class="page-container">
  <ngb-accordion #acc="ngbAccordion" activeIds="panel-0" class="page-accordion">
    <ngb-panel id="panel-0" cardClass="accordion-card">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="m-0">UNIVERSITÀ CARLO CATTANEO – LIUC (LIUC)</h3>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="logo-container">
          <a href="http://www.liuc.it/" target="_blank"> <img class="partner-image"
              src="./assets/images/liuc_logo.jpg"></a>
        </div>
        <div class="partner-description">
          <p> La LIUC, ideata nel 1989, nasce dalla volont&agrave; convergente di 300 imprenditori della Provincia di
            Varese
            (Unione degli Industriali della Provincia di Varese – UNIVA) e dell’Alto Milanese, di creare un ateneo a
            indirizzo economico-tecnico-giuridico-scientifico-manageriale,
            in grado di preparare adeguatamente i giovani, secondo le reali esigenze del mondo del lavoro.
            La LIUC rappresenta l’unica universit&agrave; italiana voluta, fondata e direttamente gestita da
            un’Associazione imprenditoriale, creata per offrire una formazione
            l più possibile aderente alle richieste del mondo aziendale e adeguata a un contesto territoriale
            caratterizzato da una diffusa presenza di attività economiche di piccola e media dimensione e
            di numerose funzioni di servizio alle imprese produttive.
          </p>
          <p> La LIUC partecipa a Smart&Touch-ID con il Centro sull’Economia e il Management nella Sanit&agrave; e nel
            Sociale, che include le competenze di management in ambito sanitario, valutazione e validazione delle
            tecnologie, analisi di processi e percorsi socio-sanitari,
            attraverso un team composto da ingegneri gestionali ed economisti aziendali.
            Ruolo peculiare di LIUC all’interno del territorio &egrave; quello di coinvolgere, non solo le realt&agrave;
            industriali, nei percorsi di validazione,
            ma di mantenere una stretta attinenza anche con le esigenze della Pubblica Amministrazione e con le istanze
            provenienti dal territorio, considerando i fabbisogni della popolazione di riferimento.
          </p>
        </div>

        <button class="button-link">
          <a class="liuc-link" href="http://www.liuc.it/" target="_blank">
            Vai al sito
          </a>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
