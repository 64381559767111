import { ChallengeService } from './challenge.service';
import { CompetenceService } from './competence.service';
import { InterestService } from './interest.service';
import { Subscription } from './../model/subscription';
import { Role } from './../model/role';
import { UserCompetence } from './../model/userCompentence';
import { UserInterest } from './../model/userInterest';
import { Competence } from './../model/competence';
import { Interest } from './../model/interest';
import { StidUser } from '../model/stidUser';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ChallengeJson } from '../model/challenge';


@Injectable({
  providedIn: 'root'
})
export class UserService {


  // constructor(private firestore: AngularFirestore,
  //             private intService: InterestService,
  //             private compService: CompetenceService,
  //             private challService: ChallengeService) { }



  // public getUserData(uid: string): Observable<StidUser>{
  //   return this.firestore.collection('users').doc(uid).snapshotChanges().pipe(
  //     map (stidUser => {
  //         if (stidUser.payload.data()) {
  //           const user = stidUser.payload.data() as StidUser;
  //           user.id = stidUser.payload.id;
  //           return user;
  //         }
  //         return null;
  //     })
  //   );
  // }

  // public registerUser(stidUser: StidUser, interests: Interest[], competences: Competence[]): Observable<unknown>{
  //   interests.forEach(int => this.addUserInterest(int, stidUser.id));
  //   competences.forEach(comp => this.addUserCompetence(comp, stidUser.id));
  //   return from(this.firestore.collection('users').doc(stidUser.id).set(stidUser));
  // }

  // public updateUser(stidUser: StidUser, interests: Interest[], competences: Competence[]): Observable<unknown>{
  //   return from(this.firestore.collection('users').doc(stidUser.id).update(stidUser));
  // }

  // public addUserInterest(interest: Interest, userId: string): void{
  //   const userInterest = {} as UserInterest;
  //   userInterest.interestId = interest.id;
  //   userInterest.userId = userId;
  //   userInterest.id = this.firestore.createId();
  //   this.firestore.collection('userInterest').doc(userInterest.id).set(userInterest);
  // }

  // public addUserCompetence(competence: Competence, userId: string): void{
  //   const userCompetence = {} as UserCompetence;
  //   userCompetence.competenceId = competence.id;
  //   userCompetence.userId = userId;
  //   userCompetence.id = this.firestore.createId();
  //   this.firestore.collection('userCompetence').doc(userCompetence.id).set(userCompetence);
  // }

  // subscribeUser(stidUser: StidUser, challenge: Challenge, role: Role): Observable<void>{
  //   this.firestore.collection('users').doc(stidUser.id).update(stidUser);
  //   const subscription = {} as Subscription;
  //   subscription.challengeId = challenge.id;
  //   subscription.id = this.firestore.createId();
  //   subscription.roleId = role.id;
  //   subscription.subscriptionDate = new Date();
  //   subscription.userId = stidUser.id;
  //   return from(this.firestore.collection('subscriptions').doc(subscription.id).set(subscription));

  // }



  // getUserInterest(userId): Observable<Interest[]>{
  //   return this.firestore.collection<UserInterest>('userInterest', ref => ref.where('userId', '==', userId)).valueChanges().pipe(
  //     map(uis => {
  //       const userInterests = [];
  //       uis.forEach(ui => userInterests.push(this.intService.getInterestById(ui.interestId)));
  //       return userInterests;
  //     })
  //   );
  // }


  // getUserCompetences(userId): Observable<Competence[]>{
  //   return this.firestore.collection<UserCompetence>('userCompetence', ref => ref.where('userId', '==', userId)).valueChanges().pipe(
  //     map(ucs => {
  //       const userCompetences = [];
  //       ucs.forEach(uc => userCompetences.push(this.compService.getCompetenceById(uc.competenceId)));
  //       return userCompetences;
  //     })
  //   );
  // }

  // public getChallengeByUser(userId: string): Observable<Challenge[]> {
  //   return this.firestore.collection<Subscription>('subscriptions', ref => ref.where('userId', '==', userId)).valueChanges().pipe(
  //     map(subs => {
  //       console.log('subs', subs);

  //       const challenges = [];
  //       subs.forEach(sub => challenges.push(this.challService.getChallengeWithId(sub.challengeId)));
  //       return challenges;
  //     })
  //   );
  // }

  // public isUserSubscribed(userId: string, challengeId: string): Observable<boolean> {
  //   return this.firestore.collection<Subscription>('subscriptions', ref => ref.where('userId', '==', userId)).valueChanges().pipe(
  //     map(subs => {
  //       for (const sub of subs) {
  //         if (sub.challengeId === challengeId) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     })
  //   );
  // }

}
