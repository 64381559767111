import { UserService } from './../../services/user.service';
import { StidUser } from './../../model/stidUser';
import { Role } from './../../model/role';
import { RoleService } from './../../services/role.service';
import { Competence } from './../../model/competence';
import { CompetenceService } from './../../services/competence.service';
import { Interest } from './../../model/interest';
import { InterestService } from './../../services/interest.service';
import { SettingsService } from './../../services/settings.service';
import { User } from 'firebase';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChallengeJson } from 'src/app/model/challenge';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss']
})
export class SubscribeModalComponent implements OnInit {

  @Input() user: StidUser;

  @Input() challenge: ChallengeJson;

  public roles: any[];

  profileForm: FormGroup;

  public selectedRole: Role;

  constructor(public activeModal: NgbActiveModal, private roleService: RoleService, private formBuilder: FormBuilder, private userService: UserService) {

    const phoneNumber = '^(\+\d{1,3}[- ]?)?\d{10}$';

    this.profileForm = this.formBuilder.group({
      phone: ['', Validators.required],
      bioSketch: ['', Validators.required]
    });

    this.roleService.roles.subscribe(rol => {
      this.roles = rol;
      console.log( 'roles', JSON.stringify(this.roles));
    });
  }

  ngOnInit(): void {
    console.log("eccolo", this.user);
  }

  public onSubmit(): void {
    this.profileForm.markAllAsTouched();
    if (this.profileForm.valid){
      console.log("eccolo", this.user);
      //this.userService.subscribeUser(this.user, this.challenge, this.selectedRole);
    }


      this.activeModal.close();

  }

  public selectRole(role: Role): void{
    this.selectedRole = role;
  }

  public close(): void{
    this.activeModal.close();
  }
}
